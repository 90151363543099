@font-face {
  font-family: 'Anuphan';
  src: url('assets/fonts/Anuphan/static/Anuphan-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Anuphan';
  src: url('assets/fonts/Anuphan/static/Anuphan-ExtraLight.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Anuphan';
  src: url('assets/fonts/Anuphan/static/Anuphan-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Anuphan';
  src: url('assets/fonts/Anuphan/static/Anuphan-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Anuphan';
  src: url('assets/fonts/Anuphan/static/Anuphan-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Anuphan';
  src: url('assets/fonts/Anuphan/static/Anuphan-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Anuphan';
  src: url('assets/fonts/Anuphan/static/Anuphan-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun';
  src: url('assets/fonts/Sarabun/Sarabun-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun';
  src: url('assets/fonts/Sarabun/Sarabun-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Sarabun';
  src: url('assets/fonts/Sarabun/Sarabun-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun';
  src: url('assets/fonts/Sarabun/Sarabun-ExtraBoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Sarabun';
  src: url('assets/fonts/Sarabun/Sarabun-ExtraLightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Sarabun';
  src: url('assets/fonts/Sarabun/Sarabun-ExtraLight.ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun';
  src: url('assets/fonts/Sarabun/Sarabun-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Sarabun';
  src: url('assets/fonts/Sarabun/Sarabun-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun';
  src: url('assets/fonts/Sarabun/Sarabun-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun';
  src: url('assets/fonts/Sarabun/Sarabun-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Sarabun';
  src: url('assets/fonts/Sarabun/Sarabun-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun';
  src: url('assets/fonts/Sarabun/Sarabun-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Sarabun';
  src: url('assets/fonts/Sarabun/Sarabun-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun';
  src: url('assets/fonts/Sarabun/Sarabun-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Sarabun';
  src: url('assets/fonts/Sarabun/Sarabun-Thin.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun';
  src: url('assets/fonts/Sarabun/Sarabun-ThinItalic.ttf');
  font-weight: 100;
  font-style: italic;
}
