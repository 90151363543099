/* You can add global styles to this file, and also import other style files */
@import '@angular/cdk/overlay-prebuilt.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  overscroll-behavior: none;
}

app-dropdown.ng-dirty.ng-invalid .time-picker,
app-dropdown.ng-invalid.ng-touched .time-picker {
  border: 1px solid red;
}

app-date-picker.ng-dirty.ng-invalid .date-picker,
app-date-picker.ng-invalid.ng-touched .date-picker {
  border: 1px solid red;
}

app-input.ng-dirty.ng-invalid input,
app-input.ng-invalid.ng-touched input {
  border: 1px solid red;
}

app-input-time.ng-dirty.ng-invalid > div:first-child,
app-input-time.ng-invalid.ng-touched > div:first-child {
  border: 1px solid red;
}

app-dropdown.ng-dirty.ng-invalid select,
app-dropdown.ng-invalid.ng-touched select {
  border: 1px solid red;
}

app-text-area.ng-dirty.ng-invalid textarea,
app-text-area.ng-invalid.ng-touched textarea {
  border: 1px solid red;
}

app-upload-file.ng-dirty.ng-invalid .file-upload,
app-upload-file.ng-invalid.ng-touched .file-upload {
  border: 1px solid red;
}

app-input-dropdown-combo.ng-dirty.ng-invalid > div,
app-input-dropdown-combo.ng-invalid.ng-touched > div {
  border: 1px solid red;
}

app-input-dropdown-combo.ng-dirty.ng-invalid select,
app-input-dropdown-combo.ng-invalid.ng-touched select {
  border-left: 1px solid red;
}

app-otp-input.ng-dirty.ng-invalid input,
app-otp-input.ng-invalid.ng-touched input {
  border: 1px solid red;
}

app-input-search-dropdown.ng-dirty.ng-invalid input,
app-input-search-dropdown.ng-invalid.ng-touched input {
  border: 1px solid red;
}

input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.checkbox-v1 input[type='checkbox' i] {
  accent-color: black;
}

input[type='date'] {
  opacity: 0;
}
